// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},

  actionTitle: " ",
  spinner: false,

  tokenExp: false,
  degiData: [],

  levelMenuList: [],
  losSpinner: false,

  saveMenuResp: {},
  smSpinner: false,
  smLoader: true,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const initialMapItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.value = resp.ref;
      resp.label = resp.val;
    });
    return resData;
  } else {
    return [];
  }
};

const ModuelAccess = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_ACCESS_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      };
    case "GET_ACCESS_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "ACCESS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_DEG_DATA":
      return {
        ...state,
        degiData: action.data,
        spinner: action.spinner,
      };

    case "LEVEL_ONE_MENU_LIST_LOADING_SPINNER":
      return {
        ...state,
        loSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "LEVEL_ONE_MENU_LIST":
      return {
        ...state,
        levelMenuList: initialMapItem(action.allData),
        losSpinner: action.spinner,
      };
    case "SAVE_MENU_LEVEL_LOADING_SPINNER":
      return {
        ...state,
        smSpinner: action.spinner,
        smLoader: action.loader,
        tokenExp: action.tokenExp,
      };
    case "SAVE_MENU_LEVEL":
      return {
        ...state,
        saveMenuResp: action.allData,
        smSpinner: action.spinner,
        smLoader: action.loader,
      };
    case "RESET_FSSPINNER":
      return {
        ...state,
        smSpinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default ModuelAccess;
