// ** Initial State
const initialState = {
  assDtlsData: {},
  asdSpinner: false,

  commonExamsData: [],
  ceSpinner: false,
  tokenExp: false,

  commonHolidaysData: [],
  chSpinner: false,

  distAllData: [],
  distData: [],
  distTotal: 1,
  distParams: {},
  distSpinner: false,

  divisionAllData: [],
  divisionData: [],
  divisionTotal: 1,
  divisionParams: {},
  divisionSpinner: false,

  assCompListAllData: [],
  assCompListData: [],
  assCompListTotal: 1,
  assCompListParams: {},
  assCompListSpinner: false,

  districtsData: [],
  districtsSpinner: false,
  districtsLoader: true,

  divsData: [],
  divsSpinner: false,
  divsLoader: true,

  expdData: {},
  expdSpinner: false,
  expdLoader: true,

  updapData: [],
  updapSpinner: false,
  updapLoader: true,

  compMobData: "",
  compMobSpinner: false,
  compMobLoader: true,
};
const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const InfoHubReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ASS_DTLS_DATA":
      return {
        ...state,
        assDtlsData: action.allData,
        asdSpinner: action.spinner,
      }
    case "GET_ASS_DTLS_LOADING_SPINNER":
      return {
        ...state,
        asdSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_COMMON_EXAMS_DATA":
      return {
        ...state,
        commonExamsData: initialItem(action.allData),
        ceSpinner: action.spinner,
      }
    case "GET_COMMON_EXAMS_LOADING_SPINNER":
      return {
        ...state,
        ceSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_COMMON_HOLIDAYS_DATA":
      return {
        ...state,
        commonHolidaysData: initialItem(action.allData),
        chSpinner: action.spinner,
      }
    case "GET_COMMON_HOLIDAYS_LOADING_SPINNER":
      return {
        ...state,
        chSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_DIST_DTLS_BY_ASS_ID_DATA":
      return {
        ...state,
        distAllData: initialItem(action.allData),
        distData: initialItem(action.data),
        distTotal: action.totalPages,
        distParams: action.params,
        distSpinner: action.spinner,
      };
    case "GET_DIST_DTLS_BY_ASS_ID_LOADING_SPINNER":
      return {
        ...state,
        distSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_DIST_DTLS_BY_ASS_ID_FILTER_DATA":
      return {
        ...state,
        distData: action.data,
        distTotal: action.totalPages,
        distParams: action.params,
      };

    case "GET_DIVISION_DTLS_BY_ASS_ID_DATA":
      return {
        ...state,
        divisionAllData: initialItem(action.allData),
        divisionData: initialItem(action.data),
        divisionTotal: action.totalPages,
        divisionParams: action.params,
        divisionSpinner: action.spinner,
      };
    case "GET_DIVISION_DTLS_BY_ASS_ID_LOADING_SPINNER":
      return {
        ...state,
        divisionSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_DIVISION_DTLS_BY_ASS_ID_FILTER_DATA":
      return {
        ...state,
        divisionData: action.data,
        divisionTotal: action.totalPages,
        divisionParams: action.params,
      };

    case "GET_COMP_DTLS_BY_DIST_DIV_DATA":
      return {
        ...state,
        assCompListAllData: initialItem(action.allData),
        assCompListData: initialItem(action.data),
        assCompListTotal: action.totalPages,
        assCompListParams: action.params,
        assCompListSpinner: action.spinner,
      };
    case "GET_COMP_DTLS_BY_DIST_DIV_LOADING_SPINNER":
      return {
        ...state,
        assCompListSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_COMP_DTLS_BY_DIST_DIV_FILTER_DATA":
      return {
        ...state,
        assCompListData: action.data,
        assCompListTotal: action.totalPages,
        assCompListParams: action.params,
      };

    case "GET_DISTRICTS_DATA":
      return {
        ...state,
        districtsData: action.data,
        districtsSpinner: action.spinner,
        districtsLoader: action.loader,
      }
    case "GET_DIST_LIST_LOADING_SPINNER":
      return {
        ...state,
        districtsSpinner: action.spinner,
        districtsLoader: action.loader,
        tokenExp: action.tokenExp,
      };

    case "GET_DIVISIONS_DATA":
      return {
        ...state,
        divsData: action.data,
        divsSpinner: action.spinner,
        divsLoader: action.loader,
      }
    case "GET_DIVISION_LIST_LOADING_SPINNER":
      return {
        ...state,
        divsSpinner: action.spinner,
        divsLoader: action.loader,
        tokenExp: action.tokenExp,
      };

    case "GET_EXISTING_POSITION_DTLS":
      return {
        ...state,
        expdData: action.data,
        expdSpinner: action.spinner,
        expdLoader: action.loader,
      }
    case "GET_EXISTING_POSITION_DTLS_LOADING_SPINNER":
      return {
        ...state,
        expdSpinner: action.spinner,
        expdLoader: action.loader,
        tokenExp: action.tokenExp,
      };

    case "UPDATE_ASSOC_POSITION":
      return {
        ...state,
        updapData: action.data,
        updapSpinner: action.spinner,
        updapLoader: action.loader,
      }
    case "UPDATE_ASSOC_POSITION_LOADING_SPINNER":
      return {
        ...state,
        updapSpinner: action.spinner,
        updapLoader: action.loader,
        tokenExp: action.tokenExp,
      };

    case "GET_COMP_MOB_NO":
      return {
        ...state,
        compMobData: action.data,
        compMobSpinner: action.spinner,
        compMobLoader: action.loader,
      }
    case "GET_COMP_MOB_NUMBER_LOADING_SPINNER":
      return {
        ...state,
        compMobSpinner: action.spinner,
        compMobLoader: action.loader,
        tokenExp: action.tokenExp,
      };

    case "RESET_SPINNER":
      return {
        ...state,
        updapSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default InfoHubReducer;
