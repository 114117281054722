// ** Initial State
const initialState = {
    distAllData: [],
    distData: [],
    distTotal: 1,
    distParams: {},
    distSpinner: false,
  };

  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const InfoHubReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DISTRICTS_DATA":
        return {
          ...state,
          districtsData: action.data,
          districtsSpinner: action.spinner,
          districtsLoader: action.loader,
        }
      case "GET_DIST_LIST_LOADING_SPINNER":
        return {
          ...state,
          districtsSpinner: action.spinner,
          districtsLoader: action.loader,
          tokenExp: action.tokenExp,
        };
  
      case "RESET_SPINNER":
        return {
          ...state,
          updapSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      default:
        return { ...state };
    }
  };
  export default InfoHubReducer;
  