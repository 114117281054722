// ** Initial State
const initialState = {
  token: [],
  spinner: false,
  tokenExp: false,

  allData: [],
  data: [],
  total: 1,
  params: {},

  dwsSpinner: false,

  filtData: [],
  filtTotal: 1,
  filtParams: {},

  complData: [],
  complSpinner: false,

  ntsData: [],
  ntsSpinner: false,

  topicData: [],
  topicSpinner: false,

  aslData: [],
  dairyAlertShow: false,
  aslSpinner: false,

  swUndRvwData: "",
  swSpinner: false,

  npUndRvwData: "",
  npSpinner: false,

  atcData: [],
  admittedCount: 0,
  atcSpinner: false,

  rtcData: [],
  retTargetCount: 0,
  retCount: 0,
  rtcSpinner: false,

  rtbData: 0,
  rtbSpinner: false
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const lessonPlanningDWSReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DAY_WISE_SCHEDULE_DATA':
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      };
    case "DAY_WISE_SCHEDULE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
        allData: [],
        data: []
      };
    case "GET_DWS_REP_DATA_FILTER":
      return {
        ...state,
        filtData: action.data,
        filtTotal: action.totalPages,
        filtParams: action.params,
      };
    case "DAY_WISE_SCHEDULE_COMPLETE":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      };
    case "DAY_WISE_SCHEDULE_COMPLETE_SPINNER":
      return {
        ...state,
        complSpinner: action.spinner,
        complTokenExp: action.tokenExp,
      };

    case "NAIPUNYA_TRACKER_SAVE":
      return {
        ...state,
        ntsData: action.allData,
        data: action.data,
        spinner: action.spinner
      };
    case "NAIPUNYA_TRACKER_SAVE_SPINNER":
      return {
        ...state,
        ntsSpinner: action.spinner,
        ntsTokenExp: action.tokenExp,
      };

    case "DIARY_SAVE":
      return {
        ...state,
        allData: initialItem(action.allData),
        dsdata: action.data,
        spinner: action.spinner
      };
    case "DIARY_SAVE_LOADING_SPINNER":
      return {
        ...state,
        dsSpinner: action.spinner,
        dsTokenExp: action.tokenExp,
      };

    case "GET_TOPIC_DETAILS":
      return {
        ...state,
        topicData: action.allData,
        topicSpinner: action.spinner
      };
    case "GET_TOPIC_DETAILS_SPINNER":
      return {
        ...state,
        topicSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "ALLOTTED_SUBJECTS_DATA_LOADING_SPINNER":
      return {
        ...state,
        aslSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "ALLOTTED_SUBJECTS_DATA":
      return {
        ...state,
        aslData: action.aslData,
        dairyAlertShow: action.dairyAlertShow,
        aslSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_SW_UNDRVW_DTLS":
      return {
        ...state,
        swUndRvwData: action.allData,
        swSpinner: action.spinner
      };
    case "GET_SW_UNDER_REVIEW_SPINNER":
      return {
        ...state,
        swSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_NP_UNDRVW_COUNT":
      return {
        ...state,
        npUndRvwData: action.allData,
        npSpinner: action.spinner
      };
    case "GET_NP_UNDRVW_LOADING_SPINNER":
      return {
        ...state,
        npSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_ADM_TARGET_COUNT_LOADING_SPINNER":
      return {
        ...state,
        atcSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ADM_TARGET_COUNT":
      return {
        ...state,
        atcData: action.data,
        admittedCount: action.admittedCount,
        atcSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_RET_TARGET_COUNT_LOADING_SPINNER":
      return {
        ...state,
        rtcSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_RET_TARGET_COUNT":
      return {
        ...state,
        rtcData: action.data,
        retTargetCount: action.retTargetCount,
        retCount: action.retCount,
        rtcSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

      case "GET_RET_BONUS_LOADING_SPINNER":
      return {
        ...state,
        rtbSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_RET_BONUS":
      return {
        ...state,
        rtbData: action.data,
        rtbSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default lessonPlanningDWSReducer;
