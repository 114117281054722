// ** Initial State
const initialState = {
    npSubjList: [],
    nslSpinner: false,
  };
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const NaipunyaReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_NP_SUBJ_LIST":
        return {
          ...state,
          npSubjList: action.allData,
          nslSpinner: action.spinner,
        }
      case "GET_NP_SUBJ_LIST_LOADING_SPINNER":
        return {
          ...state,
          nslSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
  
      case "RESET_SPINNER":
        return {
          ...state,
          updapSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      default:
        return { ...state };
    }
  };
  export default NaipunyaReducer;
  