// ** Initial State
const initialState = {
    expCrdAllData: [],
    expCrdData: [],
    expCrdTotal: 1,
    expCrdParams: {},
    expCrdSpinner: false,

    saveExpCrdData: "",
    saveExpCrdLoader: true,
    saveExpCrdSpinner: false,
};
const initialItem = (resData) => {
    if (resData) {
        resData.forEach((resp, index) => {
            resp.serial = index + 1;
        });
        return resData;
    } else {
        return [];
    }
};

const ExpenseCreditsReducer = (state = initialState, action) => {
    switch (action.type) {

        case "GET_EXPENSE_CREDITS_DATA":
            return {
                ...state,
                expCrdAllData: initialItem(action.allData),
                expCrdData: initialItem(action.data),
                expCrdTotal: action.totalPages,
                expCrdParams: action.params,
                expCrdSpinner: action.spinner,
            };
        case "GET_EXPENSE_CREDITS_LOADING_SPINNER":
            return {
                ...state,
                expCrdSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_EXPENSE_CREDITS_FILTER_DATA":
            return {
                ...state,
                expCrdData: action.data,
                expCrdTotal: action.totalPages,
                expCrdParams: action.params,
            };

        case "SAVE_EXPENSE_CREDITS_DATA":
            return {
                ...state,
                saveExpCrdData: action.resData,
                saveExpCrdLoader: action.loader,
                saveExpCrdSpinner: action.spinner,
            };
        case "SAVE_EXPENSE_CREDITS_LOADING_SPINNER":
            return {
                ...state,
                saveExpCrdLoader: action.loader,
                saveExpCrdSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };

        case "RESET_SPINNER":
            return {
                ...state,
                saveExpCrdSpinner: action.spinner,
            };
        default:
            return { ...state };
    }
};
export default ExpenseCreditsReducer;
