// ** Initial State
const initialState = {
    saveAdvStatus: {},
    sadvSpinner: false,
    sadvLoader: true,
    tokenExp: false,
  };
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const AdvReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SAVE_ADV_STATUS":
        return {
          ...state,
          saveAdvStatus: action.allData,
          sadvSpinner: action.spinner,
          sadvLoader: action.loader,
        }
      case "SAVE_ADV_STATUS_LOADING_SPINNER":
        return {
          ...state,
          sadvSpinner: action.spinner,
          sadvLoader: action.loader,
          tokenExp: action.tokenExp,
        };

        case "RESET_SPINNER":
        return {
          ...state,
          sadvSpinner: action.spinner,
        };
      default:
        return { ...state };
    }
  };
  export default AdvReducer;
  