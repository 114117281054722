// ** Initial State
const initialState = {
  proData: [],
  bankData: [],
  enqData: [],
  deptData: [],
  stateData: [],
  districData: [],
  oldStdData: [],
  proData: [],
  spinner: false,
  addSpinner: false,
  addLoader: true,
  tokenExp: false,
  espinner: false,
  asSpinner: false
};

const StudentEnquiry = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STU_ENQ_PROLIST_DATA":
      return {
        ...state,
        proData: action.data,
        espinner: action.spinner,
      };
    case "GET_STU_ENQ_STATELIST_DATA":
      return {
        ...state,
        stateData: action.data,
        spinner: action.spinner,
      };
    case "GET_STU_ENQ_DEPT_DATA":
      return {
        ...state,
        deptData: action.data,
        spinner: action.spinner,
      };
    case "GET_STU_ENQ_DISTLIST_DATA":
      return {
        ...state,
        districData: action.data,
        spinner: action.spinner,
      };
      case "GET_ALL_BANK_DATA":
        return {
          ...state,
          bankData: action.data,
        spinner: action.spinner,
        };
    case "GET_STU_ENQ_OLDLIST_DATA":
      return {
        ...state,
        oldStdData: action.data,
        spinner: action.spinner,
      };
      case "ADD_STUENQUIRY":
        return {
          ...state,
          enqData: action.data,
          addSpinner: action.spinner,
          addLoader: action.loader,
          tokenExp: action.tokenExp,
        };
    case "ADD_STU_ENQ_LOADING_SPINNER":
      return {
        ...state,
        addSpinner: action.spinner,
        addLoader: action.loader,
        tokenExp: action.tokenExp,
      };
    case "STU_ENQ_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "COMMON_STU_ENQ_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.espinner,
        tokenExp: action.tokenExp,
      };
    case "RESET_FSSPINNER":
      return {
        ...state,
        addSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default StudentEnquiry;
