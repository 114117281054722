import { DefaultConfig } from "../../../../../settings/config";
const DateFormatMonth = DefaultConfig().DateFormatMonth;
// ** Initial State
const initialState = {
  allData: [],
  vehData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  apLoader: true,
  vspinner: false,
  vloader: true,
  tokenExp: false,
  sentMsg: false,
  sentData: null
};

const initialItem = (resData) => {
  if (resData) {
    const PaperTypeList = DefaultConfig().paperTypeOpt;
    resData.forEach((resp, index) => {
      resp.Sdate = (resp.date ? DateFormatMonth(resp.date) : '')
      resp.dateValues = (resp.dateVal ? DateFormatMonth(resp.dateVal) : '')
      resp.paperTypeLabel = PaperTypeList && PaperTypeList.find((ff) => ff.value === resp.paperType) && PaperTypeList.find((ff) => ff.value === resp.paperType).label;
      resp.finGrp = resp.finGrp;
      resp.compId = resp.compId;
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const VehiclePapers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_VEHICLE_LIST_DATA":
      return {
        ...state,
        vehData: action.allData,
        vspinner: action.spinner,
        vloader: action.loader
      };
    case "VEHICLE_LIST_LOADING_SPINNER":
      return {
        ...state,
        vspinner: action.spinner,
        vloader: action.loader,
        tokenExp: action.tokenExp,
      };

    case "GET_ALL_PAPER_LIST_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_PAPER_LIST_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_PAPER_LIST":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
        sentMsg: false,
        sentData: null,
      };
    case "ADD_PAPER_LIST":
      return {
        ...state,
        allData: initialItem(action.allData),
        sentMsg: action.sentMsg,
        sentData: action.sentData,
        spinner: action.spinner,
        apLoader: action.loader
      };
    case "UPDATE_PAPER_LIST":
      return {
        ...state,
        allData: initialItem(action.allData),
        sentMsg: action.sentMsg,
        sentData: action.sentData,
        spinner: action.spinner,
        apLoader: action.loader
      };
      case "ADD_PAPER_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          apLoader: action.loader,
          tokenExp: action.tokenExp,
        };
    case "PAPER_LIST_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default VehiclePapers;
